#home {
    position: relative;
    background-color: #ff0000;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cdefs%3E%3CradialGradient id='a' cx='500' cy='500' r='60%25' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23ff0000'/%3E%3Cstop offset='1' stop-color='%23900'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='500' cy='500' r='70%25' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23FF0' stop-opacity='1'/%3E%3Cstop offset='1' stop-color='%23FF0' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1000' height='1000'/%3E%3Cg fill='none' stroke='%23F40' stroke-width='2' stroke-miterlimit='10' stroke-opacity='.5'%3E%3Ccircle cx='500' cy='500' r='725'/%3E%3Ccircle cx='500' cy='500' r='700'/%3E%3Ccircle cx='500' cy='500' r='675'/%3E%3Ccircle cx='500' cy='500' r='650'/%3E%3Ccircle cx='500' cy='500' r='625'/%3E%3Ccircle cx='500' cy='500' r='600'/%3E%3Ccircle cx='500' cy='500' r='575'/%3E%3Ccircle cx='500' cy='500' r='550'/%3E%3Ccircle cx='500' cy='500' r='525'/%3E%3Ccircle cx='500' cy='500' r='500'/%3E%3Ccircle cx='500' cy='500' r='475'/%3E%3Ccircle cx='500' cy='500' r='450'/%3E%3Ccircle cx='500' cy='500' r='425'/%3E%3Ccircle cx='500' cy='500' r='400'/%3E%3Ccircle cx='500' cy='500' r='375'/%3E%3Ccircle cx='500' cy='500' r='350'/%3E%3Ccircle cx='500' cy='500' r='325'/%3E%3Ccircle cx='500' cy='500' r='300'/%3E%3Ccircle cx='500' cy='500' r='275'/%3E%3Ccircle cx='500' cy='500' r='250'/%3E%3Ccircle cx='500' cy='500' r='225'/%3E%3Ccircle cx='500' cy='500' r='200'/%3E%3Ccircle cx='500' cy='500' r='175'/%3E%3Ccircle cx='500' cy='500' r='150'/%3E%3Ccircle cx='500' cy='500' r='125'/%3E%3Ccircle cx='500' cy='500' r='100'/%3E%3Ccircle cx='500' cy='500' r='75'/%3E%3Ccircle cx='500' cy='500' r='50'/%3E%3Ccircle cx='500' cy='500' r='25'/%3E%3C/g%3E%3Crect fill-opacity='.5' fill='url(%23b)' width='1000' height='1000'/%3E %3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;

    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
    @media screen and (max-width: 450px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;

    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 3rem;
    .badge-cmp, .tag-cmp {
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 50px;
        flex-direction: row;
        width: auto;
        box-shadow: 0 0 10px (white);
        h1 {
            color: white;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
        p {
            font-size: 18px;
            color: white;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
    }
    .tag-cmp {
        flex-direction: column;
        margin-top: 3rem;

        p {
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }

    @media screen and (min-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
      }  
}

.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;
    
    div {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-shadow: 0 0 10px (white);
        transition: transform .2s;

        &:hover {
            -ms-transform: scale(1.5); /* IE 9 */
            -webkit-transform: scale(1.5); /* Safari 3-8 */
            transform: scale(1.5); 
        }
        
        img {
            width: 65%;
            height: 65%;
            border-radius: 20%;
        }
    }
    div:nth-child(1){
        width: 100px;
        height: 100px;
        img {
            margin-left: 1.1rem;
        }
    }
    div:nth-child(2){
        margin: 1.75rem;
        width: 140px;
        height: 140px;
        img {
            margin-left: 1.6rem;
            margin-top: 0.2rem;
        }
    }
    div:nth-child(3){
        width: 80px;
        height: 80px;
        img {
            margin-left: 0.9rem;
            margin-top: 0.2rem;
        }
    }

    @media screen and (min-width: 2000px) {

          div:nth-child(1){
        width: 400px;
        height: 400px;
    }
    div:nth-child(2){

        width: 170px;
        height: 170px;
    }
    div:nth-child(3){
        width: 200px;
        height: 200px;
    }
    }
    @media screen and (max-width: 1200) {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 0;
      div{
        margin: 1rem;
      }
    }
    @media screen and (max-width: 768px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0;
    }
}

.app__header-img {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    img{
        width: 100%;
        object-fit: contain;
        z-index: 1;
    }
    .overlay_circle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 90%;
        border-radius: 50%;
    }
    @media screen and (max-width: 768px) {
        img {
            width: 100%;            // Reduce width for better fit
            border-radius: 50%;     // Add circular border radius
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Subtle shadow for depth
            border: 3px solid white; // Optional border for contrast
        }
    }

    @media screen and (max-width: 450px) {
        margin: 2rem 0;

        img {
            width: 100%; // Slightly smaller on very small screens
        }
    }
}