.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
 
}
.app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 70%;
}
.head-text {
    @media screen and (max-width: 900px){
      margin: 0 15px
       }
}
.app__profiles-bg {

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin-top: 2.5rem;
    @media screen and (max-width: 900px){
        display: flex;
        flex-direction: column;
       }
}
.app__profile-item {
    width: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);

    img{
        width: 100%;
        border-radius: 15px;
        height: 170px;
        object-fit: cover;
    }
     h2 {
        justify-content: center;
        align-items: center;
    
     }
 
    @media screen and (min-width: 2000px) {
        width: 370px;
        margin: 2rem 4rem;
        display: flex;
        flex-direction: row;
        img{
            height: 320px;
        }
    }
    @media screen and (max-width: 900px){
        margin: 3px 0.5px
        
       }
}

.app__profiles-flexbox {
    padding-top: 5rem;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%239d1c00'/%3E%3Cstop offset='1' stop-color='%23dc5517'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%23868686'/%3E%3Cstop offset='0.09' stop-color='%23ababab'/%3E%3Cstop offset='0.18' stop-color='%23c4c4c4'/%3E%3Cstop offset='0.31' stop-color='%23d7d7d7'/%3E%3Cstop offset='0.44' stop-color='%23e5e5e5'/%3E%3Cstop offset='0.59' stop-color='%23f1f1f1'/%3E%3Cstop offset='0.75' stop-color='%23f9f9f9'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top left;
}