.app__footer {
    flex: 1;
    width: 100%;
    flex-direction: column;
  
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
.app__footer-bg {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%239d1c00'/%3E%3Cstop offset='1' stop-color='%23dc5517'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%23868686'/%3E%3Cstop offset='0.09' stop-color='%23ababab'/%3E%3Cstop offset='0.18' stop-color='%23c4c4c4'/%3E%3Cstop offset='0.31' stop-color='%23d7d7d7'/%3E%3Cstop offset='0.44' stop-color='%23e5e5e5'/%3E%3Cstop offset='0.59' stop-color='%23f1f1f1'/%3E%3Cstop offset='0.75' stop-color='%23f9f9f9'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: top left;
  padding-top: 2.5rem;
}
  
  .app__footer-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 2.5rem;
   
    .app__footer-card {
      width: 250px;
      padding: 1rem;
      margin: 0 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 10px;
      cursor: pointer;
      background-color: #fef4f5;
      transition: all 0.3s ease-in-out;
  
      img {
        width: 40px;
        height: 40px;
        margin: 0 0.7rem;
      }
      a {
        text-decoration: none;
        font-weight: 500;
      }
      &:hover {
        box-shadow: 0 0 25px #fef4f5;
      }
  
      @media screen and (max-width: 450px) {
        margin: 10px;
      }
    }
  

  }
  
  .app__footer-cards .app__footer-card:last-child {
    background-color: #f2f7fb;
  }
  
  .app__footer-cards .app__footer-card:last-child:hover {
    box-shadow: 0 0 25px #f2f7fb;
  }
  
  .app__footer-form {
    width: 90%;
    flex-direction: column;
    margin: 1rem 2rem;
  
    div {
      width: 60%;
  
      margin: 0.75rem 0;
      border-radius: 10px;
      cursor: pointer;
      background-color: var(--primary-color);
  
      transition: all 0.3s ease-in-out;
      
      input,
      textarea {
        width: 100%;
        padding: 0.95rem;
        border: none;
        border-radius: 7px;
        background-color: #f2f7fb;
  
        font-family: var(--font-base);
        color: var(--secondary-color);
        outline: none;
      }
  
      textarea {
        height: 170px;
      }
  
      &:hover {
        box-shadow: 0 0 25px var(--primary-color);
      }
    }
  
    button {
      padding: 1rem 2rem;
      border-radius: 10px;
      border: none;
      background-color: #ea6529;
  
      font-weight: 600;
      color: var(--white-color);
      outline: none;
      margin: 2rem 0;
      font-family: var(--font-base);
  
      transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
      cursor: pointer;
  
      &:hover {
        background-color: black ;
      }
    }
  
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 1rem 0;
    }
  }