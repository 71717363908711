.app__works {
    flex: 1;
    width: 100%;
    flex-direction: column;
}
.app__work-bg {
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%239d1c00'/%3E%3Cstop offset='1' stop-color='%23dc5517'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%23868686'/%3E%3Cstop offset='0.09' stop-color='%23ababab'/%3E%3Cstop offset='0.18' stop-color='%23c4c4c4'/%3E%3Cstop offset='0.31' stop-color='%23d7d7d7'/%3E%3Cstop offset='0.44' stop-color='%23e5e5e5'/%3E%3Cstop offset='0.59' stop-color='%23f1f1f1'/%3E%3Cstop offset='0.75' stop-color='%23f9f9f9'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top left;
    padding-top: 2.5rem;
}
.app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    .app__work-item {
        width: 270px;
        flex-direction: column;
        margin: 2rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        
        &:hover {
            box-shadow: 0 0 20px grey;
        }

        @media screen and (min-width: 2000px) {
            width: 470px;
            padding: 1.25rem;
            border-radius: 0.75rem;
            
        }
        @media screen and (max-width: 300px) {
            width: 100%;
            margin: 1rem;
        }
    }
}
.app__work-img {
    width: 100%;
    height: 240px;
    position: relative;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
    }
    @media screen and (min-width: 2000px) {
        height: 350px;
        
    }
}

.app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;

    div{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0,0,0, 0.5);
        color: #fff;
        margin: 1rem;
        font-family: var(--font-base);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;
    }
    svg {
        color: var(--font-base);
        width: 50%;
        height: 50%;
        color: var(--white-color);
    }
}

.app__work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
    
    h4 {
        line-height: 1.5;
        margin-top: 1rem;
    }
    .p-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; // Display only 3 lines
        -webkit-box-orient: vertical;
        cursor: pointer;
        transition: max-height 0.3s ease;
    
        &.expanded {
          overflow: visible;
          text-overflow: unset;
          display: block;
          -webkit-line-clamp: unset;
          max-height: none; // Allow full height when expanded
        }
      }
      .read-more-button {
        margin-top: 10px;
        background-color: transparent;
        border: none;
        color: #3498db;
        cursor: pointer;
        font-size: 0.9rem;
        font-weight: 600;
        text-align: left;
        padding: 0;
        outline: none;
    
        &:hover {
          text-decoration: underline;
        }
      }
    .app__work-tag {
        position: absolute;
        border-radius: 10px;
        padding: 0.5rem 1rem;
        top: -25px;
        background-color: #fff;
    }
}