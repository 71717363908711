.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: #010101;
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    z-index: 2;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    li {
        margin: 0 1rem;
        cursor: pointer;
        flex-direction: column;
         a {
          color: #fff;
          text-decoration: none;
          flex-direction: column;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 18px;
          transition: all 0.3s ease-in-out;
          
          &:hover {
            color: #ea6529;
          }
         }
   
    }
    @media screen and (max-width: 900px) {
        display: none;
    }
}

.app__navbar-menu {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    
    svg{
        width: 75%;
        height: 75%;
        color: black;
    }
    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        padding: 1rem;
        width: 80%;
        height: 100vh;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        background-color: black;
        background-size: cover;
        background-repeat: repeat;
        box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);
       svg {
        width: 35px;
        height: 35px;
        color: white;
        margin: 0.5rem 1rem;
       }
       ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        color: whitesmoke;
       }
       li {
        margin: 1rem;
        a {
            color: var(--grey-color);
            text-decoration: none;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: var(--secondary-color);
            }
        }
       }
 
    }
    @media screen and (min-width: 900px){
        display: none;
       }
}

.app__navbar-name {
    font-size: 40px;
    font-weight: 400;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.7);
    color: white;
    font: 700 2em/1 "Oswald", sans-serif;
    letter-spacing: 0;
    padding: 0.25em 0 0.325em;
    display: block;
    margin: 0 auto;
    text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
    background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
    -webkit-background-clip: text;
    background-clip: text;

    /* Animate Background Image */
    -webkit-text-fill-color: transparent;
    -webkit-animation: aitf 80s linear infinite;
    animation: aitf 80s linear infinite;

    /* Activate hardware acceleration for smoother animations */
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

@-webkit-keyframes aitf {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
}

@keyframes aitf {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
}
